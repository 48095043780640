import React, {Component} from "react"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import { Link } from "gatsby"
import WomenWork__rs from "../images/womenwork.png"
import igadget__rs from "../images/igadget2.jpg"
import Petdog__rs from "../images/petdog.png"
import Amazon_case from "../images/amazon_casestudy.jpg"
import Atg_website from "../images/atg-website-img.png"
import Perennial_img from "../images/perennialcycle/perennial-img.png"
import Perennial_seo from "../images/perennialcycle/seo-perennial.jpg"
import Betty_img from "../images/bitty-biddy/bitty-resources.jpg"
import Seed_Planet from "../images/atg/seed_planet.png"
import Dipro_img from "../images/diamonds-pro/dipro_img.png"
import Ansel_Brand from "../images/anselivy/ansel-res.png"
import ddu_resources from "../images/diamond-designs/ddu-resources.jpg"
import ATG_Seeds from "../images/atg-conv/atg-seeds.jpg"
import DR_B from "../images/drB/casestudylogodrb.png"

import {Helmet} from "react-helmet"
export default class Resources extends Component {
  constructor(props) {
    super(props);
    this.state={
      url: ''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }
  render() {
    return (
        <Layout>
          <Helmet>
            <title>Case Studies on Magento & Shopify Stores </title>
            <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
            <meta property="fb:admins" content="1046558399"/>
            <link rel="canonical" href={this.state.url}/>
            <meta name="description"
                  content="We constantly strive to deliver on our promise of in-depth client collaborations fueled by clear & consistent communication. Take an in-depth look into how we sailed through a few of our projects"/>
            <meta property="og:site_name" content="CueForGood"/>
            <meta property="fb:app_id" content="289086684439915"/>
            <meta property="og:url" content={this.state.url}/>
            <meta property="og:title"
                  content="Case Studies on Magento & Shopify Stores"/>
            <meta property="og:description"
                  content="We constantly strive to deliver on our promise of in-depth client collaborations fueled by clear & consistent communication. Take an in-depth look into how we sailed through a few of our projects"/>
            <meta property="og:image" content="https://www.CueForGood.com/images/cb.jpg"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:description"
                  content="We constantly strive to deliver on our promise of in-depth client collaborations fueled by clear & consistent communication. Take an in-depth look into how we sailed through a few of our projects"/>
            <meta name="twitter:title"
                  content="Case Studies on Magento & Shopify Stores"/>
            <meta name="twitter:site" content="@CueForGood"/>
            <meta name="twitter:image" content="https://www.CueForGood.com/images/cb.jpg"/>
          </Helmet>
          <section className=" resourace-casestudy">
            <div className="container">
            <h1 className="heading_main">Case Studies</h1>
              <div className="ser_sec_row_lt pd_right_40">
                <div className="ser_sec_row_lt_text">
                  <p>
                    Meticulously honing our skills over the last 15 years, we constantly strive to deliver on our
                    promise
                    of in-depth client collaborations fueled by clear & consistent communication.
                  </p>
                </div>
              </div>
              <div className="ser_sec_row_rt pd_left_40">
                <div className="ser_sec_row_lt_text">
                  <p>
                    Adhering to timing & budget expectations of our clients, we build & modify the solutions their
                    customers are looking for, thereby reinforcing the brand identity in the industry. Take an in-depth
                    look into how we sailed through a few of our projects.
                  </p>
                </div>
              </div>
            </div>
          </section>
           <section className=" resources-petdoors fleex right-img ">
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_lt pd_right_40">
                <img  src={DR_B} alt="DR_B" className="img-responsive" />
              
              </div>
              <div className="ser_sec_row_rt pd_left_40 ">
                <h2 className="heading_main">Dr. B Dental Solutions</h2>
                <span className="highlight-span" style={{marginRight: '10px'}}>Paid Ads</span>
                <h4>
                Dr. B Dental Solutions Achieves 215% Revenue Growth with Google Shopping Ads 
                </h4>

                <Link to="/case-study/dental-ecommerce-google-shopping-ads" className="btn main_cta">
                  Read more
                </Link>
              </div>
            </div>
          </div>
          </section>
          <section className=" resources-petdoors fleex ">
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_lt pd_right_40">
                <img  src={ATG_Seeds} alt="All that grows seeds " className="img-responsive" />
              
              </div>
              <div className="ser_sec_row_rt pd_left_40 ">
                <h2 className="heading_main">AllThatGrows</h2>
                <span className="highlight-span" style={{marginRight: '10px'}}>Paid Ads</span>
                <h4>
                AllThatGrows Achieves 2.97x ROAS & 500+ Conversions with Google Ads.
                </h4>

                <Link to="/case-study/seed-ecommerce-on-shopify-google-ads" className="btn main_cta">
                  Read more
                </Link>
              </div>
            </div>
          </div>
          </section>
          <section className=" resources-petdoors fleex right-img">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_lt pd_left_40">
                  <img
                      src={Dipro_img}
                      alt="Womanswork"
                      className="img-responsive"
                  />
                </div>
                <div className="ser_sec_row_rt pd_left_40 ">
                  <h2 className="heading_main">The Diamond Pro</h2>
                  <span className="highlight-span" style={{marginRight: '10px'}}>SEO</span>
                  <h4>
                  Increasing Affiliate Clicks by 169% with SEO for Diamond Industry Client.
                  </h4>

                  <Link to="/case-study/seo-affiliate-marketing-diamond-industry/" className="btn main_cta">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" resources-petdoors fleex ">
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_lt pd_right_40">
                <img  src={igadget__rs} alt="Ansel and Ivy" className="img-responsive" />
              
              </div>
              <div className="ser_sec_row_rt pd_left_40 ">
                <h2 className="heading_main">iGadget</h2>
                <span className="highlight-span" style={{marginRight: '10px'}}>Google Shopping Engine</span>
                <h4>
                Achieving 23.59% of Total Orders through Google Shopping
                </h4>

                <Link to="/case-study/mobile-repairs-accessory-store-igadget/" className="btn main_cta">
                  Read more
                </Link>
              </div>
            </div>
          </div>
          </section>
          <section className=" resources-petdoors fleex right-img">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_lt pd_left_40">
                  <img
                      src={ddu_resources}
                      alt="Womanswork"
                      className="img-responsive"
                  />
                </div>
                <div className="ser_sec_row_rt pd_left_40 ">
                  <h2 className="heading_main">Diamond Designs</h2>
                  <span className="highlight-span" style={{marginRight: '10px'}}>FACEBOOK/ INSTAGRAM</span><span className="highlight-span" style={{marginRight: '10px'}}>PAID ADS</span>
                  <h4>
                  Achieving a ROAS of 5x with Facebook/ Instagram ads within just 5 months.
                  </h4>

                  <Link to="/case-study/diamond-designs/" className="btn main_cta">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </section>

            <section className=" resources-petdoors fleex ">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_lt pd_right_40">
                  <img src={Ansel_Brand} alt="Ansel and Ivy" className="img-responsive" />
                
                </div>
                <div className="ser_sec_row_rt pd_left_40 ">
                  <h2 className="heading_main">Ansel & Ivy </h2>
                  <span className="highlight-span" style={{marginRight: '10px'}}>Google Shopping Engine</span>
                  <h4>
                   How CueForGood helped Ansel & Ivy generate 12.35% of its total revenue from Google Shopping.
                  </h4>

                  <Link to="/case-study/google-shopping-ansel-and-ivy" className="btn main_cta">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </section>

           <section className=" resources-petdoors fleex right-img">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_lt pd_left_40">
                  <img src={Seed_Planet} alt="Seed Planet" className="img-responsive" />
                
                </div>
                <div className="ser_sec_row_rt pd_right_40 ">
                  <h2 className="heading_main">AllThatGrows</h2>
                  <span className="highlight-span" style={{marginRight: '10px'}}>Facebook/ Instagram</span><span className="highlight-span" style={{marginRight: '10px'}}>Paid Ads</span>
                  <h4>
                    Optimizing Facebook/ Instagram paid ads to achieve a 3.27x ROAS, consistently over the years.
                  </h4>

                  <Link to="/case-study/high-roas-during-pandemic" className="btn main_cta">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </section>
              <section className=" resources-petdoors fleex ">
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_rt pd_right_40">
                <img
                  src={Perennial_seo}
                  alt="Perennial Seo"
                  className="img-responsive"
                />
              </div>
              <div className="ser_sec_row_lt pd_left_40">
                <h2 className="heading_main">Perennial Cycle</h2>
                <span className="highlight-span">SEO</span>
                <h4>
                  How PerennialCycle increased YOY Organic Revenue by 155.63% with just Elemental SEO.
                  </h4>

                <Link to="/case-study/seo-perennial-cycle" className="btn main_cta">
                  Read more
                  </Link>
              </div>
            </div>
          </div>
        </section>
         <section className=" resources-petdoors fleex right-img">
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_lt pd_left_40">
                <img
                  src={Betty_img}
                  alt="Perennial cycle"
                  className="img-responsive"
                />
              </div>
              <div className="ser_sec_row_rt pd_left_40 ">
                <h2 className="heading_main">Betty and Biddy</h2>
                <span className="highlight-span">Google Shopping Engine</span>
                <h4>
                  Raising the revenue by 1699% for Betty and Biddy with Google Shopping Campaigns
                  </h4>

                <Link to="/case-study/betty-and-biddy-google-shopping" className="btn main_cta">
                  Read more
                  </Link>
              </div>
            </div>
          </div>
        </section>
        <section className=" resources-petdoors fleex">
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_lt pd_right_40">
                <img
                  src={Perennial_img}
                  alt="Perennial cycle"
                  className="img-responsive"
                />
              </div>
              <div className="ser_sec_row_rt pd_left_40 ">
                <h2 className="heading_main">Perennial Cycle</h2>
                <span className="highlight-span">Google Shopping Engine</span>
                <h4>
                 How Google Shopping Engine Played A Big Role In Increasing The Revenue Meter By 616.91%.
                  </h4>

                <Link to="/case-study/google-shopping-perennial-cycle" className="btn main_cta">
                  Read more
                  </Link>
              </div>
            </div>
          </div>
        </section>

            <section className=" resources-petdoors fleex right-img">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_lt pd_left_40">
                  <img
                      src={Amazon_case}
                      alt="AllThatGrows"
                      className="img-responsive"
                  />
                </div>
                <div className="ser_sec_row_rt pd_right_40 ">
                  <h2 className="heading_main">AllThatGrows</h2>
                  <span className="highlight-span">Marketplace Optimization</span> 
                  <h4>
                    Leveraging the Amazon Marketplace to increase revenue for AllThatGrows.
                  </h4>

                <Link to="/case-study/amazon-marketplace/" className="btn main_cta">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" resources-petdoors fleex ">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <img src={Petdog__rs} alt="PetDoors" className="img-responsive"/>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">PetDoors</h2>
                  <span className="highlight-span">magento</span> <span className="highlight-span">SEO</span>
                  <h4>
                    Merging revenue-driven blogs to increase organic sessions by 106.94% for PetDoors.
                  </h4>

                <Link to="/case-study/merging-blog-pages-seo/" className="btn main_cta">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </section>
           <section className=" resources-petdoors fleex right-img">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_lt pd_left_40">
                  <img
                      src={WomenWork__rs}
                      alt="Womanswork"
                      className="img-responsive"
                  />
                </div>
                <div className="ser_sec_row_rt pd_right_40 ">
                  <h2 className="heading_main">Womanswork</h2>
                  <span className="highlight-span" style={{marginRight: '10px'}}>Magento</span><span className="highlight-span" style={{marginRight: '10px'}}>Speed Optimization</span>
                  <h4>
                    Ensuring speed optimization for Womanswork's Magento 2 eStore.
                  </h4>

                  <Link to="/case-study/site-speed-ww/" className="btn main_cta">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" resources-petdoors fleex ">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <img
                      src={Atg_website}
                      alt="Atg Website"
                      className="img-responsive"
                  />
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">AllThatGrows</h2>
                  <span className="highlight-span">Shopify</span> <span className="highlight-span">Creative Design</span> <span className="highlight-span">Digital Marketing</span>
                  <h4>
                    Building brand identity, packaging designs & marketing campaigns for AllThatGrows.
                  </h4>

                <Link to="/case-study/allthatgrows/" className="btn main_cta">
                    Read more 
                  </Link>
                </div>
              </div>
            </div>
          </section>
         
          <section className="home-resourse__row home-resourse__row_res">
            <div className="container">
              <h2 className="heading_main">Resources</h2>
              <div className="resourse__lt pd_right_40">
                <p>
                  Be sure to check off all the points on this specially-curated Conversion Rate Optimisation (CRO)
                  before
                  investing in your next big eCommerce venture.
                </p>
                <h3>91 Point CRO Checklist & Infographic</h3>
                <Link to="/holygrail-of-ecommerce-conversion-rate-optimization-91-point-checklist/"
                      className="main_cta" title="Download 91-Point Checklist for eCommerce Conversion Rate Optimization-1 (opens in new tab)">
                  Download
                </Link>
              </div>
              <div className="resourse__rt pd_left_40">
                <p>
                  Follow our 40-Point Checklist Guide for quick tips & feasible steps to smoothly switch the HTTP
                  version
                  of your website to a more secure version, HTTPS.
                </p>
                <h3>40 Point Checklist for a Successful HTTP to HTTPS Migration </h3>
                <Link to="/40-point-checklist-for-a-successful-http-to-https-migration/" className="main_cta" title="Download 40-Point Checklist for HTTP to HTTPS Migration-1 (opens in new tab)">
                  Download
                </Link> 
              </div>
            </div>
          </section>

          <Clutch/>
        </Layout>
    )
  }
}
                  